import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { industryApplicationsMap, industryToFilename } from './shared/models/industryApplicationsMap';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const firebaseConfig = {
  apiKey: "AIzaSyBSh0hFuRmKmIkj5KLBJvAuNrd9yogWsJI",
  authDomain: "business-pilot-ai.firebaseapp.com",
  projectId: "business-pilot-ai",
  storageBucket: "business-pilot-ai.appspot.com",
  messagingSenderId: "518676625826",
  appId: "1:518676625826:web:a6bcd7a1b4521ad76d1afb",
  measurementId: "G-TCCQPP83T5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const firestore = getFirestore(app);

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif'
    ].join(','),
  },
});

function App() {
  const [url, setUrl] = useState('');
  const [uid, setUid] = useState<string | null>(null);
  const [industry, setIndustry] = useState('');
  const [useCases, setUseCases] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [urlIsPassed, setUrlIsPassed] = useState(false);

  useEffect(() => {
    // Sign in anonymously
    const auth = getAuth();
    signInAnonymously(auth)
      .then(({ user }) => {
        setUid(user.uid);
      })
      .catch((error) => {
        console.error("Error signing in anonymously:", error);
      });

    // Handle potential URL passed as a query parameter
    const queryParams = new URLSearchParams(window.location.search);
    const passedUrl = queryParams.get('data');
    if (passedUrl) {
      const decodedUrl = decodeURIComponent(passedUrl);
      setUrl(decodedUrl);
      setUrlIsPassed(true);
    }
  }, []);

  useEffect(() => {
    if (uid) {
      const docRef = doc(firestore, `industries/${uid}`);
      const unsubscribe = onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setIndustry(data.industry || 'No industry data');
        } else {
          console.log('No such document!');
        }
      }, (error) => {
        console.error("Error getting document:", error);
      });
  
      return () => unsubscribe();
    }
  }, [uid]);

  const handleSubmit = useCallback(async () => {
    if (!uid) {
      console.error("User is not signed in");
      return;
    }
    setIsLoading(true);
  
    const determineIndustry = httpsCallable(functions, 'determineIndustry');
  
    try {
      const result = await determineIndustry({ url, uid });
      console.log('Function result:', result.data);
    } catch (error) {
      console.error('Error calling Firebase function:', error);
    }
    setIsLoading(false);
  }, [uid, url]);

  useEffect(() => {
    if(urlIsPassed && uid && url){
      handleSubmit();
      setUrlIsPassed(false);
    }
  }, [urlIsPassed, uid, url, handleSubmit]);

  useEffect(() => {
    if (industry) {
      try {
        const cases = industryApplicationsMap.get(industry) || [];
        setUseCases(cases);
      } catch (error) {
        console.error("Error setting use cases:", error);
      }
    }
  }, [industry]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <div>
            {isLoading ? (
              <div>
                <CircularProgress color="primary" />
                <Typography style={{ padding: '10px' }}>Scanning your website to understand your business and identifying opportunities to use AI...</Typography>
              </div>
            ) : (
              uid && industry && (
                <div className="industry-container" style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div>
                    <Typography variant="h6">
                      Industry: {industry}
                    </Typography>
                    <div style={{ padding: '20px 20px 0px 20px' }}>
                      <img
                          src={industryToFilename(industry)}
                          alt={industry}
                          style={{ 
                              maxWidth: '250px', 
                              maxHeight: '250px', 
                              borderRadius: '20px' 
                          }}
                      />
                  </div>
                  </div>
                  <div className="list-large-margin">
                    <List>
                      {useCases.map((useCase, index) => (
                        <React.Fragment key={index}>
                          <ListItem>
                            <Typography>{useCase}</Typography>
                          </ListItem>
                          {index < useCases.length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </List>
                  </div>
                </div>
              )
            )}
          </div>
        </header>
      </div>
    </ThemeProvider>
  );
}

export default App;
