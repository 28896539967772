export const industryApplicationsMap = new Map<string, string[]>([
  [
    "Healthcare",
    [
      "Patient data management",
      "Diagnostic process",
      "Treatment planning",
      "Drug discovery",
      "Appointment scheduling",
      "Billing",
      "Insurance claims processing",
      "NLP medical assistant",
      "Medical imaging",
      "Remote patient monitoring",
      "Telemedicine Consultations",
    ],
  ],
  [
    "Finance & Banking",
    [
      "Risk assessment",
      "Fraud detection",
      "Customer service",
      "Personalized financial planning",
      "Loan processing automation",
      "Portfolio Management",
    ],
  ],
  [
    "Retail & E-commerce",
    [
      "Inventory management",
      "Personalized shopping experience",
      "Price optimization",
      "Supply chain management",
      "Assist logistics",
      "Demand prediction",
      "Marketing optimization",
      "CRM",
    ],
  ],
  [
    "Manufacturing",
    [
      "Optimize production lines",
      "Predict maintenance needs",
      "Enhance quality control",
      "Manage/optimize supply chains",
      "Quality assurance",
      "Worker Safety Monitoring",
      "Employee training",
    ],
  ],
  [
    "Transportation",
    [
      "Route optimization",
      "Predictive maintenance",
      "Diver voice assistance",
      "Assisted driving",
      "Remote vehicle diagnostics",
      "Traffic flow analysis",
    ],
  ],
  [
    "Logistics",
    [
      "Predictive maintenance",
      "Fleet management",
      "Quality control",
      "Warehousing operations",
      "Customs documentation automation",
      "Real-time shipment tracking",
    ],
  ],
  [
    "Real Estate",
    [
      "Automate property valuations",
      "Customer inquiries",
      "Virtual property tours",
      "Contract processing",
      "Market trend analysis",
      "Lease management automation",
    ],
  ],
  [
    "Education",
    [
      "Automated grading",
      "Administrative tasks",
      "Personalized learning experience",
      "Identify dropout risk",
      "Lesson translation",
      "Curriculum development",
      "Student success prediction",
      "Career counseling",
    ],
  ],
  [
    "Media & Entertainment",
    [
      "Personalized recommendations",
      "Content creation",
      "Analyze viewer preferences",
      "Ad targeting optimization",
      "Sentiment analysis of viewer feedback",
      "Video editing",
    ],
  ],
  [
    "Legal",
    [
      "Legal document analysis",
      "Due diligence",
      "Legal research",
      "Precedent analysis",
      "Litigation prediction and analysis",
      "Contract automation",
    ],
  ],
  [
    "Agriculture",
    [
      "Crop management",
      "Weed & disease detection",
      "Fertilizer use optimization",
      "Selective breeding assistance",
      "Water use optimization",
    ],
  ],
  [
    "Tourism & Hospitality",
    [
      "Personalized itinerary planning",
      "Automated check-in/out systems",
      "Customer preference analysis",
      "Virtual concierge services",
      "Dynamic pricing",
      "Event planning",
      "Translation",
    ],
  ],
  [
    "Energy",
    [
      "Smart grid management",
      "Renewable energy optimization",
      "Demand forecasting",
      "Predictive asset maintenance",
      "Energy storage optimization",
      "Trading and risk management",
    ],
  ],
  [
    "Aerospace",
    [
      "Flight operations optimization",
      "Maintenance prediction",
      "Air traffic control automation",
      "Satellite imagery analysis",
    ],
  ],
  [
    "Other",
    [
      "Process automation",
      "Data Synthesis and Augmentation",
      "Personalized Virtual Assistants",
      "Content generation",
      "Customer service",
      "Voice Generation and Synthesis",
      "Marketing optimization",
      "Demand prediction",
      "Personalized recommendations",
      "AI-Driven Research and Development",
      "Simulation and Modeling",
    ],
  ],
]);

export function getCommaSeparatedIndustries(): string {
  return Array.from(industryApplicationsMap.keys()).join(", ");
}

export function industryToFilename(industry: string): string {
  const formattedIndustry = industry
    .toLowerCase()
    .replace(/ & /g, "_")
    .replace(/ /g, "_");

  return `images/ai_generated/${formattedIndustry}.png`;
}
